<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            :error-messages="nameErrors"
            :label="$t('ModeratorName')"
            @blur="$v.item.name.$touch()"
            @input="$v.item.name.$touch()"
            v-model="item.name"
            clearable
            required
          />
        </v-col>
        <v-col cols="12" sm="12">
          <v-text-field
            :error-messages="emailErrors"
            :label="$t('ModeratorEmail')"
            @blur="$v.item.email.$touch()"
            @input="$v.item.email.$touch()"
            v-model="item.email"
            :hint="$t('SendModInvitationViaMailHint')"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import has from "lodash/has";
import {validationMixin} from "vuelidate";
import {email, required} from "vuelidate/lib/validators";

export default {
  name: "ModeratorForm",
  mixins: [validationMixin],
  props: {
    values: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {
      },
    },
    initialValues: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    item() {
      return this.initialValues || this.values;
    },
    nameErrors() {
      const errors = [];
      if (this.$v.item.name) {
        if (!this.$v.item.name.$dirty) {
          return errors;
        }

        has(this.violations, "name") && errors.push(this.violations.name);

        !this.$v.item.name.required &&
        errors.push(this.$t("Field is required"));
      }
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (this.$v.item.email) {
        if (!this.$v.item.email.$dirty) {
          return errors;
        }

        has(this.violations, "email") && errors.push(this.violations.email);

        !this.$v.item.email.email &&
        errors.push(this.$t("Invalid email address."));
      }
      return errors;
    },
    violations() {
      return this.errors || {};
    },
  },
  validations: {
    item: {
      name: {
        required,
      },
      email: {
        email,
      },
    },
  },
};
</script>
