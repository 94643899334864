<template>
  <v-dialog
      v-model="showDialog"
      persistent
      max-width="380px"
      :key="participant['@id']"
  >
    <v-card v-if="postalScriptAddress">
        <v-card-title>Script per Post an:</v-card-title>
        <v-card-text>
          <template v-if="postalScriptAddress.additional"><strong>{{ postalScriptAddress.additional }}</strong><br />
          </template>
          {{ postalScriptAddress.streetNr }}<br />
          {{ postalScriptAddress.zipCode }} {{ postalScriptAddress.city }}<br />
          {{ postalScriptAddress.country }}
        </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="showDialog = false">{{ $t("Close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AddressMixin from "@/mixins/AddressMixin";

export default {
  name: "ScriptPerPostInfoDialog",
  mixins: [AddressMixin,],
  props: {
    value: Boolean,
    participant: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    postalScriptAddress() {
      if (this.participant['@id'] && this.participant.postalScriptAddress) {
        return this.resolveAddress(this.participant.postalScriptAddress);
      }
      return null;
    }
  },
};
</script>
