<template>
  <v-dialog v-model="showSignatureListDialogForm" persistent max-width="600px">
    <v-card>
      <v-card-title><span class="headline">{{ $t("GenerateSignatureList") }}</span></v-card-title>
      <v-card-text>
        <v-row  v-if="isInhouse">
          <v-col cols="12">
            <v-text-field outlined v-model="location" :label="$t('Location')" :disabled="!this.isInhouse" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="showSignatureListDialogForm = false">{{ $t("Close") }}</v-btn>
        <v-spacer />
        <v-btn color="primary" @click="sendForm">{{ $t("Submit") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import DocumentMixin from "@/mixins/DocumentMixin";
import TenantMixin from "@/mixins/TenantMixin";

const servicePrefix = "Seminar";

export default {
  name: "SignatureListDialog",
  servicePrefix,
  mixins: [DocumentMixin, TenantMixin, validationMixin],
  data() {
    return {
      location: null
    }
  },
  props: {
    value: Boolean,
    seminarItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showSignatureListDialogForm: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    sendForm() {
      this.downloadDocument(
        `${this.seminarItem["@id"]}/generateSignatureList?location=${this.location}`,
        "Anwesenheitsliste.pdf",
        true
      );
      this.showSignatureListDialogForm = false;
    },
  },
};
</script>
