<template>
  <div>
    <v-data-table
      :headers="participantHeaders"
      :items="participantObjects"
      :options.sync="options"
      :loading="!participantsLoaded"
      :hide-default-footer="hideTableFooter"
      :loading-text="$t('Loading...')"
      class="elevation-1"
      item-key="@id"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("Participants") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="refreshParticipantData()"
            :title='$t("refreshData")'
            fab
            icon
            elevation="2"
            small
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <template>
            <v-btn
              v-if="hasRole('ROLE_CREATE_PARTICIPANT')"
              :disabled="locked"
              @click="createOrEditParticipantHandler(null)"
              color="success"
              style="margin-left: 20px;"
            >
              {{ $t("AddParticipant") }}
            </v-btn>
          </template>

          <ParticipantDialog
            v-if="hasRole('ROLE_UPDATE_PARTICIPANT') || hasRole('ROLE_CREATE_PARTICIPANT')"
            v-model="showParticipantDialogForm"
            :participant-item="participantFormItem"
            :handle-created="onCreated"
            :handle-updated="onUpdated"
            :show-handle="showParticipantDialogForm"
            :seminarId="defaultItemProps.seminar"
            :is-no-online="isNoOnline"
            :current-participants="participants"
            :title="$t('AddParticipant')"
            @close="participantDialogControl(true)"
            @updatedDiscounts="discountsUpdated()"
          />
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        <template v-if="item.customer">
          {{ getProfileItemBasics(getCustomerItem(item.customer, "profile"), "firstName") }}
          {{ getProfileItemBasics(getCustomerItem(item.customer, "profile"), "lastName") }}
        </template>
        <template v-else>
          {{ item.name }}
          <template v-if="isInhouse">{{ item.email }}</template>
        </template>
      </template>
      <template v-slot:item.customerNumber="{ item }">
        <v-btn
          plain
          color="primary"
          @click="openCustomerDialog(resolveCustomer(item.customer))"
          style="padding: 0"
        >
          {{ getCustomerItem(item.customer, "customerNumber") }}
        </v-btn>
      </template>
      <template v-slot:item.postalScript="{ item }">
        <v-btn
          plain
          @click="openScriptPerPostInfoDialog(item)"
          style="padding: 0"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
              >
                <template v-if="item.postalScriptAddress">mdi-mailbox-up</template>
                <template v-else>mdi-mailbox-open-outline</template>
              </v-icon>
            </template>
            <span>
              <template v-if="item.postalScriptAddress">Script per Post bestellt</template>
              <template v-else>Kein Script per Post bestellt</template>
            </span>
          </v-tooltip>
        </v-btn>
      </template>
      <template v-slot:item.addons="{ item }">
        <v-btn
          plain
          @click="openAddonInfoDialog(item)"
          style="padding: 0"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
              >
                <template v-if="item.addons.length > 0">mdi-puzzle</template>
                <template v-else>mdi-puzzle-outline</template>
              </v-icon>
            </template>
            <span>{{ item.addons.length }} Addon(s) gebucht</span>
          </v-tooltip>
        </v-btn>
      </template>
      <template v-slot:item.comment="{ item }">
        <v-btn
          plain
          @click="openCommentInfoDialog(item)"
          style="padding: 0"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
              >
                <template v-if="item.comment">mdi-comment-alert</template>
                <template v-else>mdi-comment-off-outline</template>
              </v-icon>
            </template>
            <span>
              <template v-if="item.comment">Kommentar verfügbar</template>
              <template v-else>Kein Kommentar verfügbar</template>
            </span>
          </v-tooltip>
        </v-btn>
      </template>
      <template v-slot:item.invoiceState="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
            >
              <template v-if="item.firstInvoice">
                <template v-if="getInvoiceItem(item.firstInvoice, 'paid')">mdi-receipt-text-check-outline</template>
                <template v-else>mdi-email-check-outline</template>
              </template>
              <template v-else>mdi-email-remove-outline</template>
            </v-icon>
          </template>
          <span>
            <template v-if="item.firstInvoice">
              <template v-if="getInvoiceItem(item.firstInvoice, 'paid')">Bezahlt</template>
              <template v-else>Erstellt & versendet</template>
              ({{ getInvoiceItem(item.firstInvoice, 'number') }})
            </template>
            <template v-else>Nicht erstellt & versendet</template>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.registrationDate="{ item }">
        {{ formatDateTime(item.createdAt, "L") }}
      </template>
      <template
        v-if="!locked"
        v-slot:item.action="{ item }"
      >
        <v-tooltip
          bottom
          v-if="
            hasRole('ROLE_UPDATE_PARTICIPANT') && !seminar.seminarConfig.completed
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="createOrEditParticipantHandler(item)"
              class="mr-2"
              small
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>{{ $t("ParticipantUpdate") }}</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="
            hasRole('ROLE_UPDATE_PARTICIPANT') &&
            !seminar.seminarConfig.completed &&
            !seminar.seminarConfig.cancelled &&
            seminar.seminarConfig.autoInvoice &&
            seminarHappens &&
            isPublicBookable
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="confirmSendInvoice(item)"
              class="mr-2"
              small
            >
              mdi-cash
            </v-icon>
          </template>
          <span>
            <template v-if="item.firstInvoice">{{ $t("ReSendInvoice") }}</template>
            <template v-else>{{ $t("SendInvoice") }}</template>
          </span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="!seminar.seminarConfig.completed && !isNoOnline"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="sendInviteMail(item)"
              class="mr-2"
              small
            >
              mdi-send
            </v-icon>
          </template>
          <span>{{ $t("ParticipantInvite") }}</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="
            hasRole('ROLE_DELETE_PARTICIPANT') && !seminar.seminarConfig.completed
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="handleDelete(item)"
              class="mr-2"
              small
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $t("ParticipantDelete") }}</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="
            hasRole('ROLE_UPDATE_PARTICIPANT') && seminar.seminarConfig.completed
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="toggleParticipantParticipated(item)"
              class="mr-2"
            >
              <template v-if="item.participated">
                mdi-toggle-switch
              </template>
              <template v-else>
                mdi-toggle-switch-off-outline
              </template>
              mdi-pencil
            </v-icon>
          </template>
          <span>
            <template v-if="item.participated">
              {{ $t("ParticipantParticipatedToggle") }}
            </template>
            <template v-else>
              {{ $t("ParticipantNotParticipatedToggle") }}
            </template>
          </span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="
            hasRole('ROLE_UPDATE_PARTICIPANT') &&
            seminar.seminarConfig.completed &&
            item.participated
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="downloadParticipantConfirmation(item)"
              class="mr-2"
            >
              mdi-download
            </v-icon>
          </template>
          <span>{{ $t("DownloadParticipantConfirmation") }}</span>
        </v-tooltip>
        <!-- TODO: Public Bookable: Show info about payment status -->
      </template>
      <template v-slot:no-data>
        {{ $t("NoParticipantsYet") }}
      </template>
    </v-data-table>
    <CustomerInfoDialog
      v-model="showCustomerDialog"
      :customer="customerDialogItem"
      :key="customerDialogItem.id"
      @close="showCustomerDialog = false"
    />
    <ScriptPerPostInfoDialog
      v-model="showScriptPerPostDialog"
      :participant="scriptPerPostDialogItem"
      :key="scriptPerPostDialogItem.id"
      @close="showScriptPerPostDialog = false"
    />
    <CommentInfoDialog
      v-model="showCommentDialog"
      :participant="commentDialogItem"
      :key="commentDialogItem.id"
      @close="showCommentDialog = false"
    />
    <AddonInfoDialog
      v-model="showAddonDialog"
      :participant="addonDialogItem"
      :key="addonDialogItem.id"
      @close="showAddonDialog = false"
    />
  </div>
</template>
<script>
  import ParticipantDialog from "@/components/participant/Dialog";
  import ParticipantMixin from "@/mixins/ParticipantMixin";
  import remove from "lodash/remove";
  import { mapActions } from "vuex";
  import ProfileMixin from "@/mixins/ProfileMixin";
  import CustomerMixin from "@/mixins/CustomerMixin";
  import DocumentMixin from "../../mixins/DocumentMixin";
  import NotificationMixin from "@/mixins/NotificationMixin";
  import TenantMixin from "@/mixins/TenantMixin";
  import CustomerInfoDialog from '@/components/customer/CustomerInfoDialog';
  import ScriptPerPostInfoDialog from '@/components/participant/ScriptPerPostInfoDialog';
  import CommentInfoDialog from '@/components/participant/CommentInfoDialog';
  import AddonInfoDialog from "@/components/participant/AddonInfoDialog";
  import InvoiceMixin from '@/mixins/InvoiceMixin';
  import { formatDateTime } from '@/utils/dates';

  export default {
    name: "ParticipantListAndAdd",
    components: {
      CustomerInfoDialog,
      ParticipantDialog,
      ScriptPerPostInfoDialog,
      CommentInfoDialog,
      AddonInfoDialog
    },
    mixins: [ParticipantMixin, ProfileMixin, CustomerMixin, DocumentMixin, NotificationMixin, TenantMixin, InvoiceMixin],
    props: {
      values: {
        type: Object,
        required: false,
      },
      participants: {
        type: Array,
        default: () => [],
      },
      participantsCount: {
        type: Number,
        default: 0,
      },
      defaultItemProps: {
        type: Object,
        required: true,
      },
      showTitle: {
        type: Boolean,
        required: false,
        default: () => true,
      },
      seminarHappens: {
        type: Boolean,
        required: true,
      },
      isPublicBookable: {
        type: Boolean,
        required: true,
      },
      objectType: {
        type: String,
        required: true,
      },
      locked: {
        type: Boolean,
        required: true,
      },
      isNoOnline: {
        type: Boolean,
        default: false,
        required: true,
      },
      seminar: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        options: {
          itemsPerPage: -1
        },
        forceLoading: false,
        showParticipantDialogForm: false,
        participantFormItem: {},
        confirmSendParticipantInvoiceMessage: this.$t(
          "confirmSendParticipantInvoiceMessage",
        ),
        confirmReSendParticipantInvoiceMessage: this.$t(
          "confirmReSendParticipantInvoiceMessage",
        ),
        showCustomerDialog: false,
        customerDialogItem: {},
        showScriptPerPostDialog: false,
        scriptPerPostDialogItem: {},
        showCommentDialog: false,
        commentDialogItem: {},
        showAddonDialog: false,
        addonDialogItem: {},
      };
    },
    computed: {
      participantHeaders() {
        let headerSet = [
          {
            text: this.$i18n.t("name"),
            value: "name",
            align: 'start'
          },
          {
            text: this.$i18n.t("comment"),
            value: "comment",
            sortable: false,
            align: "center",
            width: '90px'
          },
        ];

        if (!this.isInhouse) {
          headerSet.unshift({
            text: this.$i18n.t("customerNumber"),
            value: "customerNumber",
            sortable: false,
            align: 'start',
            width: '140px'
          });
        }

        if (this.isPublicBookable) {
          headerSet.push({
            text: this.$i18n.t("postalScriptShort"),
            value: "postalScript",
            align: "center",
            width: '90px',
            sortable: false,
          })
          headerSet.push({
            text: this.$i18n.t("Addons"),
            value: "addons",
            align: "center",
            width: '90px',
            sortable: false,
          })
          headerSet.push({
            text: this.$i18n.t("invoice"),
            value: "invoiceState",
            align: "center",
            width: '90px',
            sortable: false,
          })
          headerSet.push({
            text: this.$i18n.t("registrationDate"),
            value: "registrationDate",
            sortable: false,
            align: 'end',
            width: '140px'
          })
        }

        headerSet.push({
          text: this.$i18n.t("Actions"),
          value: "action",
          sortable: false,
          align: "end",
        });

        return headerSet;
      },
      participantObjects() {
        return this.resolveParticipantItems(this.participants);
      },
      participantsLoaded() {
        return !this.forceLoading && this.participants.length === this.participantObjects.length;
      },
      hideTableFooter() {
        return !(this.participantObjects.length > 10);
      },
    },
    methods: {
      log(m) {
        console.log(m);
      },
      ...mapActions("participant", {
        participantAction: "action",
      }),
      formatDateTime,
      onCreated(item) {
        if (item[this.objectType] !== null) {
          this.participants.push(item["@id"]);
        }
        this.$emit("updateParticipants", this.participants);
      },
      onUpdated(item) {
        this.showMessage(item["@id"] + " updated");
      },
      createOrEditParticipantHandler(participantItem) {
        this.participantFormItem = participantItem ?? this.defaultItemProps;
        this.participantDialogControl();
      },
      participantDialogControl(close = false) {
        this.showParticipantDialogForm = !close;
      },
      deleteParticipantHandler(item) {
        this.deleteParticipant(item);
        let cleaned = remove(this.participants, (uid) => {
          return !(uid === item["@id"]);
        });
        this.$emit("updateParticipants", cleaned);
      },
      sendInviteMail(item) {
        this.$confirm(
          item.invited
            ? this.$t("InviteParticipantAgain")
            : this.$t("InviteParticipant"),
          {
            buttonTrueText: this.$t("Yes"),
            buttonFalseText: this.$t("No"),
          },
        ).then((response) => {
          if (!response) {
            return;
          }
          this.participantAction({
            item,
            action: "invite",
            params: [{ key: "force", value: item.invited }],
          });
        });
      },
      toggleParticipantParticipated(item) {
        this.$confirm(
          item.participated
            ? this.$t("ParticipantNotParticipatedSet")
            : this.$t("ParticipantParticipatedSet"),
          {
            buttonTrueText: this.$t("Yes"),
            buttonFalseText: this.$t("No"),
          },
        ).then((response) => {
          if (!response) {
            return;
          }
          this.updateParticipant({
            ["@id"]: item["@id"],
            participated: !item.participated,
          });
        });
      },
      downloadParticipantConfirmation(item) {
        this.participantAction({
          item,
          action: "generateParticipantConfirmation",
        });
      },
      handleDelete(participant) {
        this.$confirm(this.$t("Are you sure you want to delete this item?"), {
          buttonTrueText: this.$t("Delete"),
          buttonFalseText: this.$t("Cancel"),
        }).then((response) => {
          if (!response) {
            return;
          }
          this.deleteParticipantHandler(participant);
        });
      },
      discountsUpdated() {
        // this triggers a reload seminar so the prices get updated
        this.$emit("updateParticipants", this.participants);
      },
      confirmSendInvoice(item) {
        let message = (item.firstInvoice) ? this.confirmReSendParticipantInvoiceMessage : this.confirmSendParticipantInvoiceMessage;

        this.$confirm(message, {
          buttonTrueText: this.$t("Yes"),
          buttonFalseText: this.$t("No"),
        }).then((response) => {
          if (!response) {
            return;
          }
          this.participantAction({
            item: item,
            action: "generateAndSendParticipantInvoice",
          });
        });
      },
      openCustomerDialog(customer) {
        this.customerDialogItem = customer;
        this.showCustomerDialog = true;
      },
      openScriptPerPostInfoDialog(participant) {
        if (participant.postalScriptAddress) {
          this.scriptPerPostDialogItem = participant;
          this.showScriptPerPostDialog = true;
        } else {
          this.showError(this.$t("noPostalScriptOrdered"));
        }
      },
      openCommentInfoDialog(participant) {
        if (participant.comment) {
          this.commentDialogItem = participant;
          this.showCommentDialog = true;
        } else {
          this.showError(this.$t("noCommentAvailable"));
        }
      },
      openAddonInfoDialog(participant) {
        if (participant.addons.length > 0) {
          this.addonDialogItem = participant;
          this.showAddonDialog = true;
        } else {
          this.showError(this.$t("noAddonAvailable"));
        }
      },
      async refreshParticipantData() {
        // participantsLoaded
        this.forceLoading = true;
        await this.reloadParticipantsTrackable(this.participants);
        this.forceLoading = false;
      }
    },
    watch: {
      deletedParticipantItem() {
        this.showMessage(this.$t("ParticipantDeleted"));
      },
      participantHandled() {
        if (this.participantHandled === null) {
          return;
        }
        if (
          this.participantActionHandled === "invite" &&
          this.participantHandled.invited === true
        ) {
          this.showMessage(this.$t("ParticipantInvited"));
          this.participantActionHandled = null;
          this.participantHandled = null;
        }
        if (this.participantActionHandled === "generateParticipantConfirmation") {
          this.getAndDownloadDocument(this.participantHandled.participantConfirmation.document);
        }
        if (
          this.participantActionHandled === "generateAndSendParticipantInvoice"
        ) {
          this.showMessage(this.$t("ParticipantInvoiceSent"));
          this.participantActionHandled = null;
          this.participantHandled = null;
        }
      },
    },
  };
</script>
