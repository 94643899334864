<template>
  <div>
    <template v-if="typeof this.clickFunction === 'function'">
      <v-chip :color="color" @click="clickFunction" :text-color="textColor" class="mr-2">
        <v-icon left>{{ icon }}</v-icon>
        {{ label }}
      </v-chip>
    </template>
    <template v-else>
      <v-chip :color="color" :text-color="textColor" class="mr-2">
        <v-icon left>{{ icon }}</v-icon>
        {{ label }}
      </v-chip>
    </template>
  </div>
</template>

<script>
export default {
  name: 'StatusChip',
  data() {
    return {};
  },
  props: {
    isPositive: {
      type: Boolean,
      required: true,
    },
    typePositive: {
      type: String,
      default: () => 'success',
      required: false,
    },
    typeNegative: {
      type: String,
      default: () => 'warning',
      required: false,
    },
    iconPositive: {
      type: String,
      default: () => 'mdi-check',
      required: false,
    },
    iconNegative: {
      type: String,
      default: () => 'mdi-close',
      required: false,
    },
    labelPositive: {
      type: String,
      required: true,
    },
    labelNegative: {
      type: String,
      required: true,
    },
    clickFunction: {
      type: Function,
      required: false
    }
  },
  computed: {
    label() {
      return (this.isPositive) ? this.labelPositive : this.labelNegative;
    },
    icon() {
      return (this.isPositive) ? this.iconPositive : this.iconNegative;
    },
    color() {
      return this.getColor((this.isPositive) ? this.typePositive : this.typeNegative);
    },
    textColor() {
      return this.getTextColor((this.isPositive) ? this.typePositive : this.typeNegative);
    }
  },
  methods: {
    getColor(type) {
      switch (type) {
        case 'success':
          return 'green';
        case 'warning':
          return 'amber';
        case 'danger':
          return 'deep-orange';
        case 'notice':
          return 'light-blue lighten-3';
        case 'primary':
          return 'primary';
      }
      return null;
    },
    getTextColor(type) {
      switch (type) {
        case 'success':
          return 'white';
        case 'danger':
          return 'white';
        case 'primary':
          return 'white';
      }
      return null;
    },
  },
};
</script>
