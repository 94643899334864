<template>
  <v-dialog
    v-model="showDialog"
    persistent
    max-width="380px"
    :key="participant['@id']"
  >
    <v-card v-if="addonObjects">
      <v-card-title>Gebuchte Addons:</v-card-title>
      <v-card-text>
        <v-row
          no-gutters
          v-for="addon in bookedAddonsNested"
          :key="addon['@id']"
        >
          <v-col
            cols="9"
            class="addon-title"
          >
            {{ addon.title }}
          </v-col>
          <v-col
            cols="3"
            class="addon-price text-right"
          >
            {{ showEuroCurrency(addon.price) }}
          </v-col>
          <v-col
            cols="11"
            offset="1"
          >
            <v-row
              no-gutters
              v-for="addonChild in addon.children"
              :key="addonChild['@id']"
            >
              <v-col
                cols="9"
                class="addon-title"
              >
                {{ addonChild.title }}
              </v-col>
              <v-col
                cols="3"
                class="addon-price text-right"
              >
                {{ showEuroCurrency(addonChild.price) }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="showDialog = false"
        >{{ $t("Close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import AddonMixin from "@/mixins/AddonMixin";
  import { showEuroCurrency } from '@/utils/money';
  export default {
    name: "AddonInfoDialog",
    mixins: [AddonMixin,],
    props: {
      value: Boolean,
      participant: {
        type: Object,
        required: true,
      },
    },
    methods: {
      showEuroCurrency,
    },
    computed: {
      showDialog: {
        get() {
          return this.value;
        },
        set(value) {
          if (!value) {
            this.$emit("close");
          }
        },
      },
      addonObjects() {
        if (this.participant['@id'] && this.participant.addons) {
          return this.resolveAddons(this.participant.addons);
        }
        return null;
      },
      bookedAddonsNested() {
        if (!this.addonObjects) {
          return []
        }
        const bookedAddonIds = this.addonObjects.map((addon) => addon['@id']);
        const mainAddons = this.addonObjects.filter((addon) => {
          return addon.parent === null
        });

        const bookedAddonsGrouped = mainAddons.map((addon) => {
          if (addon.children && addon.children.length > 0) {
            addon.children = addon.children.filter((subAddon) => {
              return bookedAddonIds.includes(subAddon);
            });
          }
          return addon;
        });


        return bookedAddonsGrouped.map(addon => {
          if (addon.children && addon.children.length > 0) {
            addon.children = addon.children.map((subAddon) => {
              return this.resolveAddon(subAddon);
            });
          }
          return addon;
        });
      },
    },
  };
</script>
