import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
  data() {
    return {
      loadingExpenses: [],
    };
  },
  computed: {
    ...mapFields('expense', {
      expenseIds: 'allIds',
      expenseIdsCached: "cacheAllIds",
      deletedExpenseItem: 'deleted',
    }),
    ...mapGetters('expense', {findExpense: 'find'}),
  },
  methods: {
    ...mapActions('expense', {
      retrieveExpense: 'load',
      deleteExpense: 'del',
      updateExpense: 'update',
    }),
    resolveExpenseItems(itemIds) {
      if(typeof itemIds === "undefined"){
        return [];
      }
      return itemIds.map((itemId) => {
        if (!this.expenseIdsCached.includes(itemId) && !this.loadingExpenses.includes(itemId)) {
          this.loadingExpenses.push(itemId);
          this.retrieveExpense(decodeURIComponent(itemId));
        }
        return this.findExpense(itemId);
      }).filter(e => e);
    },
    calculateExpenses(expenses, participants) {
      var total = 0;
      expenses.forEach((expense) => {
        total += (expense.amount * ((expense.perParticipant) ? participants : 1));
      });
      return total;
    }
  }
};
