<template>
  <v-dialog
      v-model="showDialog"
      persistent
      max-width="380px"
      :key="participant['@id']"
  >
    <v-card>
        <v-card-title>Kommentar:</v-card-title>
        <v-card-text v-text="participant.comment" />
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="showDialog = false">{{ $t("Close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "CommentInfoDialog",
  props: {
    value: Boolean,
    participant: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>
