import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
  data() {
    return {
      loadingMeetings: [],
    };
  },
  computed: {
    ...mapFields("meeting", {
      meetingIds: "allIds",
      meetingIdsCached: "cacheAllIds",
    }),
    ...mapGetters("meeting", {
      meetingItems: 'list',
      findMeeting: "find",
    }),
  },
  methods: {
    ...mapActions("meeting", {
      retrieveMeeting: "load",
      fetchAllMeetings: 'fetchAll',
      updateMeeting: "update",
      meetingActions: "action",
    }),
    resolveMeeting(itemId) {
      if (!this.isMeetingLoaded(itemId) && !this.loadingMeetings.includes(itemId)) {
        this.loadingMeetings.push(itemId);
        this.retrieveMeeting(decodeURIComponent(itemId));
      }
      return this.findMeeting(itemId);
    },
    resolveMeetings(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        return this.resolveMeeting(itemId);
      }).filter(e => e);
    },
    getMeetingItem(meetingIri, type) {
      if (meetingIri !== null) {
        let meeting = this.resolveMeeting(meetingIri);
        if (meeting) {
          return meeting[type];
        }
      }
      return '';
    },
    isMeetingLoaded(itemId) {
      return this.meetingIdsCached.includes(itemId);
    },
  }
};
