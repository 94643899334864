import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  data() {
    return {
      loadingVenues: [],
    };
  },
  watch: {},
  computed: {
    ...mapFields('venue', {
      venuesById: 'byId',
      venueIds: 'allIds',
      venueIdsCached: "cacheAllIds",
    }),
    ...mapGetters('venue', {
      venueItems: 'list',
      findVenue: 'find'
    }),
  },
  methods: {
    ...mapActions('venue', {
      retrieveVenue: 'load',
      fetchAllVenues: 'fetchAll',
    }),
    resolveVenue(itemId) {
      if (!this.venueIdsCached.includes(itemId) && !this.loadingVenues.includes(itemId)) {
        this.loadingVenues.push(itemId);
        this.retrieveVenue(decodeURIComponent(itemId));
      }
      return this.findVenue(itemId) ?? null;
    },
    resolveVenueAsync(itemId) {
      const poll = resolve => {
        if (this.isVenueLoaded((itemId))) {
          resolve(this.resolveVenue(itemId));
        } else {
          this.resolveVenue(itemId);
          setTimeout(() => poll(resolve), 400);
        }
      }
      return new Promise(poll);
    },
    isVenueLoaded(itemId) {
      return this.venueIdsCached.includes(itemId);
    },
    resolveVenues(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        return this.resolveVenue(itemId);
      }).filter(e => e);
    },
    getVenueItem(itemId, type) {
      let venue = this.resolveVenue(itemId);
      if (venue !== null) {
        return venue[type];
      }
      return '';
    },
  }
};
