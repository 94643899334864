<template>
  <v-dialog v-model="showDialogForm" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("JoinModeratorOrAdd") }}</span>
      </v-card-title>
      <v-card-text>
        <ModeratorForm
          :errors="violations"
          :values="item"
          :loading="isLoading"
          :seminarId="seminarId"
          ref="moderatorDialogForm"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="showDialogForm = false"> {{ $t("Close") }}</v-btn>
        <v-spacer/>
        <v-btn class="mainAccent lighten-3" @click="resetForm">{{ $t("Reset") }}</v-btn>
        <v-btn color="primary" @click="sendForm">{{ $t("Submit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <Loading :visible="isLoading"/>
  </v-dialog>
</template>

<script>
import {createHelpers} from "vuex-map-fields";
import ModeratorForm from "./Form";
import NotificationMixin from "../../mixins/NotificationMixin";
import Loading from "@/components/Loading";
import {mapActions, mapGetters} from "vuex";

const servicePrefix = "Participant";

const {mapFields} = createHelpers({
  getterType: "participant/getField",
  mutationType: "participant/updateField",
});

export default {
  name: "ModeratorDialog",
  servicePrefix,
  mixins: [NotificationMixin],
  components: {
    Loading,
    ModeratorForm,
  },
  props: {
    value: Boolean,
    handleCreated: {
      type: Function,
      required: false,
    },
    handleUpdated: {
      type: Function,
      required: false,
    },
    seminarId: {
      type: String,
      required: true,
    },
    meeting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      item: {
        name: null
      },
      createMessage: this.$t("successfullyCreated"),
      updateMessage: this.$t("successfullyUpdated"),
    };
  },
  computed: {
    ...mapFields([
      "error",
      "isLoading",
      "created",
      "violations",
      "updated",
      "byId",
    ]),
    ...mapGetters("participant", ["find"]),
    showDialogForm: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    ...mapActions("meeting", {
      meetingActions: "action",
    }),
    sendForm() {
      if (this.isValidForm()) {
        let model = this.$refs.moderatorDialogForm.$v.item.$model;
        this.meetingActions({item: this.meeting, action: 'joinAsModerator', data: model, dtoResponse: true});
        this.showMessage(this.$t('ModeratorInvited'))
        this.resetForm();
      }
    },
    reset() {
      this.$refs.moderatorDialogForm.$v.$reset();
    },
    resetForm() {
      if (this.$refs["moderatorDialogForm"]) {
        this.item = {
          name: this.getUserName()
        };
        this.$refs.moderatorDialogForm.$v.$reset();
      }
    },
    isValidForm() {
      this.$refs.moderatorDialogForm.$v.$touch();
      return !this.$refs.moderatorDialogForm.$v.$invalid;
    },
    getUserName() {
      let name = this.userData.email;

      if (this.userData.firstName && this.userData.lastName) {
        name = this.userData.firstName + ' ' + this.userData.lastName;
        if (this.userData.title) {
          name = this.userData.title + ' ' + name;
        }
      }
      return name;
    }
  },
  created() {
    this.item.name = this.getUserName();
  }
};
</script>
