<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-text-field
            :error-messages="textErrors"
            :label="$t('description')"
            @blur="$v.item.text.$touch()"
            @input="$v.item.text.$touch()"
            v-model="item.text"
            required
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            outlined
            :label="$t('ExpenseAmount')"
            :error-messages="amountErrors"
            @blur="$v.item.amount.$touch()"
            @input="$v.item.amount.$touch()"
            prefix="€"
            required
            v-model.number="item.amount"
          />
        </v-col>
        <v-col cols="12">
          <v-checkbox
            :error-messages="perParticipantErrors"
            :label="$t('perParticipant')"
            @blur="$v.item.perParticipant.$touch()"
            @input="$v.item.perParticipant.$touch()"
            v-model="item.perParticipant"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';

export default {
  name: 'ExpenseForm',
  mixins: [validationMixin],
  props: {
    values: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => {
      }
    },
    initialValues: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      text: null,
      amount: null,
      perParticipant: null,
    };
  },
  computed: {
    item() {
      return this.initialValues || this.values;
    },
    //region formErrors
    textErrors() {
      const errors = [];
      if (!this.$v.item.text.$dirty) {
        return errors;
      }
      has(this.violations, 'text') && errors.push(this.violations.text);
      !this.$v.item.text.required && errors.push(this.$t('Field is required'));
      return errors;
    },
    amountErrors() {
      const errors = [];
      if (!this.$v.item.amount.$dirty) {
        return errors;
      }
      has(this.violations, 'amount') && errors.push(this.violations.amount);
      !this.$v.item.amount.required && errors.push(this.$t('Field is required'));
      return errors;
    },
    perParticipantErrors() {
      const errors = [];
      if (!this.$v.item.perParticipant.$dirty) {
        return errors;
      }
      has(this.violations, 'perParticipant') && errors.push(this.violations.perParticipant);

      return errors;
    },
    violations() {
      return this.errors || {};
    },
    //endregion
  },
  validations: {
    item: {
      text: {
        required,
      },
      amount: {
        required,
      },
      perParticipant: {}
    }
  },
}
</script>
