<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="12" v-if="this.isIndividuallyBookable">
          <template v-if="currentCustomer === null">
            <CustomerSearch :on-selected="onCustomerAcUpdated" :required="true" />
          </template>
          <template v-else>
            <v-chip>
              {{ currentCustomer.customerNumber }}:
              {{ getProfileItemBasics(currentCustomer.profile, 'firstName') }}
              {{ getProfileItemBasics(currentCustomer.profile, 'lastName') }}
              <template v-if="getProfileItemBasics(currentCustomer.profile, 'company')">
                | {{ getProfileItemBasics(currentCustomer.profile, 'company') }}
              </template>
            </v-chip>
          </template>
        </v-col>
        <v-col cols="12" v-if="!this.isIndividuallyBookable">
          <v-text-field
            :error-messages="nameErrors"
            :label="$t('ParticipantName')"
            @blur="$v.item.name.$touch()"
            @input="$v.item.name.$touch()"
            v-model="item.name"
            :disabled="this.isIndividuallyBookable"
          />
        </v-col>
        <v-col cols="12" v-if="!this.isIndividuallyBookable && !this.isNoOnline">
          <v-text-field
            :error-messages="emailErrors"
            :label="$t('email')"
            @blur="$v.item.email.$touch()"
            @input="$v.item.email.$touch()"
            v-model="item.email"
            :disabled="this.isIndividuallyBookable"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            :error-messages="commentErrors"
            :label="$t('comment')"
            @blur="$v.item.comment.$touch()"
            @input="$v.item.comment.$touch()"
            v-model="item.comment"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import { validationMixin } from 'vuelidate';
import { email, requiredIf } from 'vuelidate/lib/validators';
import TenantMixin from '../../mixins/TenantMixin';
import ProfileMixin from '@/mixins/ProfileMixin';
import CustomerMixin from '@/mixins/CustomerMixin';
import CustomerSearch from '@/components/customer/Search.vue';

export default {
  name: 'ParticipantForm',
  components: { CustomerSearch },
  mixins: [validationMixin, TenantMixin, ProfileMixin, CustomerMixin],
  props: {
    values: {
      type: Object,
      required: true
    },
    seminarId: {
      type: String,
      required: true
    },
    currentParticipants: {
      type: Array,
      required: false
    },
    isNoOnline: {
      type: Boolean,
      default: false,
      required: false
    },
    errors: {
      type: Object,
      default: () => {}
    },
    initialValues: {
      type: Object,
      default: () => {}
    },
    currentCustomer: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      currentSelectedCustomer: null
    };
  },
  computed: {
    item() {
      return this.initialValues || this.values;
    },
    currentCustomerObject: {
      get() {
        return this.currentCustomer
      },
      set(currentCustomer) {
        return currentCustomer
      }
    },
    nameErrors() {
      const errors = [];
      if (this.$v.item.name) {
        if (!this.$v.item.name.$dirty) {
          return errors;
        }

        has(this.violations, 'name') && errors.push(this.violations.name);

        !this.$v.item.name.required && errors.push(this.$t('Field is required'));
      }
      return errors;
    },
    commentErrors() {
      const errors = [];
      if (this.$v.item.comment) {
        if (!this.$v.item.comment.$dirty) {
          return errors;
        }

        has(this.violations, 'comment') && errors.push(this.violations.comment);
      }
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (this.$v.item.email) {
        if (!this.$v.item.email.$dirty) {
          return errors;
        }

        has(this.violations, 'email') && errors.push(this.violations.email);

        !this.$v.item.email.required && errors.push(this.$t('Field is required'));
        !this.$v.item.email.email && errors.push(this.$t('Invalid email address.'));
      }
      return errors;
    },
    violations() {
      return this.errors || {};
    },
    isIndividuallyBookable() {
      return this.currentSeminarSettings['individuallyBookable'];
    }
  },
  methods: {
    log(message) {
      console.log(message);
    },
    onCustomerAcUpdated(selectedCustomer) {
      if (selectedCustomer) {
        this.item.customer = selectedCustomer['@id'];
        this.currentSelectedCustomer = selectedCustomer;
      } else {
        this.item.customer = null;
        this.currentSelectedCustomer = null;
      }
    }
  },
  validations() {
    return {
      item: {
        customer: {
          required: requiredIf(function () {
            return this.isIndividuallyBookable;
          })
        },
        name: {
          required: requiredIf(function () {
            return !this.isIndividuallyBookable && this.isNoOnline;
          })
        },
        email: {
          email,
          required: requiredIf(function () {
            return !this.isIndividuallyBookable && !this.isNoOnline;
          })
        },
        comment: {}
      }
    };
  },
};
</script>
