import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import remove from "lodash/remove";

export default {
  data() {
    return {
      loadingParticipants: [],
    };
  },
  computed: {
    ...mapFields('participant', {
      participantIds: 'allIds',
      participantIdsCached: "cacheAllIds",
      deletedParticipantItem: 'deleted',
      participantHandled: 'handled',
      participantActionHandled: 'handleAction'
    }),
    ...mapGetters('participant', { findParticipant: 'find' }),
  },
  methods: {
    ...mapActions('participant', {
      retrieveParticipant: 'load',
      deleteParticipant: 'del',
      updateParticipant: 'update',
    }),
    resolveParticipantItems(itemIds, force = false) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        if (force || !this.participantIdsCached.includes(itemId) && !this.loadingParticipants.includes(itemId)) {
          this.loadingParticipants.push(itemId);
          this.retrieveParticipant(decodeURIComponent(itemId));
        }
        return this.findParticipant(itemId);
      }).filter(e => e);
    },
    async reloadParticipantsTrackable(itemIds) {
      itemIds.forEach((itemId) => {
        this.loadingParticipants.push(itemId);
        this.retrieveParticipant(decodeURIComponent(itemId)).then((item) => {
          remove(this.loadingParticipants, (n) => n === item['@id']);
        });
      });

      let sleep = ms => new Promise(r => setTimeout(r, ms));
      let waitFor = async function waitFor(f) {
        while (!f()) await sleep(400);
        return f();
      };
      return await waitFor(() => this.loadingParticipants.length === 0)
    },
    resolveParticipant(itemId) {
      if (!this.participantIdsCached.includes(itemId) && !this.loadingParticipants.includes(itemId)) {
        this.loadingParticipants.push(itemId);
        this.retrieveParticipant(decodeURIComponent(itemId));
      }
      return this.findParticipant(itemId);
    },
  }
};
