<template>
  <v-dialog v-model="showDialogForm" persistent max-width="600px">
    <v-card :loading="isUploading">
      <v-card-title>{{ $t("FileAdd") }}</v-card-title>
      <v-card-text>
        <v-file-input
          :rules="imageRules"
          :label="$t('Files')"
          v-model="attachment"
          accept="application/pdf"
          prepend-icon="mdi-attachment"
          outlined
        />
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="showDialogForm = false">{{ $t("Close") }}</v-btn>
        <v-spacer/>
        <v-btn color="primary"
               :loading="isUploading"
               :disabled="typeof attachment === 'undefined' || attachment === null"
               @click="uploadAttachment()">{{ $t("Upload") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogMixin from "@/mixins/DialogMixin";
import DocumentMixin from "@/mixins/DocumentMixin";

const servicePrefix = "Participant";

export default {
  name: "UploadDialog",
  servicePrefix,
  mixins: [DialogMixin, DocumentMixin],
  props: {
    value: Boolean,
    maxSize: {
      type: Number,
      required: true
    },
    handleUploaded: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      isUploading: false,
      imageRules: [
        (value) =>
          !value ||
          value.size < this.maxSizeBytes ||
          `File size should be less than ${this.maxSize} MB!`,
      ],
      attachment: null,
    };
  },
  computed: {
    showDialogForm: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    maxSizeBytes() {
      return this.maxSize * 1024 * 1024;
    }
  },
  methods: {
    uploadAttachment() {
      if (this.attachment) {
        this.isUploading = true;
        this.uploadDocument(this.attachment);
      }
    },
  },
  watch: {
    uploadedDocument() {
      if (!this.uploadedDocument) {
        return;
      }
      this.isUploading = false;
      this.handleUploaded({
        name: this.uploadedDocument.name,
        iri: this.uploadedDocument["@id"],
        contentUrl: this.uploadedDocument.contentUrl,
      })
      this.attachment = null;
    },
  }
};
</script>
