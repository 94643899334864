<template>
  <v-card outlined tile>
    <v-card-text>
      <v-data-table
          :headers="fileHeaders"
          :items="files"
          class="elevation-1"
          item-key="@id"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{ $t("Files") }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="showUploadDialog=true" color="primary" small>
              {{ $t("FileAdd") }}
            </v-btn>
            <UploadDialog
                v-model="showUploadDialog"
                :max-size="8"
                :handle-uploaded="onUploaded"
                @close="showUploadDialog = false"
            />
          </v-toolbar>
        </template>
        <template v-slot:item.action="{ item }">
          <ActionCell
              :handle-delete="() => deleteAttachment(item)"
              :handle-show="() => showAttachmentHandler(item)"
          >
          </ActionCell>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import DocumentMixin from "../../mixins/DocumentMixin";
import ActionCell from "@/components/ActionCell";
import UploadDialog from "@/components/document/UploadDialog";

export default {
  name: "DocumentList",
  components: { ActionCell, UploadDialog },
  mixins: [DocumentMixin],
  data() {
    return {
      showUploadDialog: false,
      fileHeaders: [
        { text: this.$t("name"), value: "name" },
        {
          text: this.$t("Actions"),
          value: "action",
          sortable: false,
        },
      ],
    };
  },
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    files() {
      return this.resolveDocuments(this.documents);
    },
  },
  methods: {
    onUploaded(uploadedFile) {
      this.documents.push(uploadedFile.iri)
      this.$emit('uploaded')
      this.showUploadDialog = false;
    },
    deleteAttachment(attachment) {
      this.deleteDocument({ "@id": attachment["@id"] });
      const indexToRemove = this.documents.findIndex((el) => el === attachment["@id"]);
      if (indexToRemove > -1) {
        this.documents.splice(indexToRemove, 1)
      }
    },
    showAttachmentHandler(attachment) {
      this.getAndDownloadDocument(attachment['@id']);
    },
  },
};
</script>
