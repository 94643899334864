<template>
  <div>
    <v-dialog v-model="showDialog" scrollable max-width="800px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small text outlined v-bind="attrs" v-on="on" class="open-dialog">
          {{ openLabel }}
          <v-icon right v-if="openIcon">{{ openIcon }}</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title><span class="headline">{{ title }}</span></v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4" v-html="contentHtml"/>
        <v-divider></v-divider>
        <v-card-actions>
          <template v-if="isEditable">
            <v-btn color="primary" @click="handleEdit">{{ $t('Submit') }}</v-btn>
          </template>
          <v-spacer/>
          <v-btn text @click="showDialog = false">{{ $t('Close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "ContentHtmlDialog",
  mixins: [],
  components: {},
  props: {
    value: Boolean,
    title: {
      type: String,
      required: true
    },
    contentHtml: {
      type: String,
      required: true
    },
    openLabel: {
      type: String,
      required: true
    },
    openIcon: {
      type: String,
      required: false
    },
    handleEdit: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      item: {},
      showDialog: false,
    }
  },
  computed: {
    isEditable() {
      return this.handleEdit !== null;
    }
  },
  methods: {
    sendForm() {
      // TODO: edit handler
    },
  },
  created() {
    //this.item = {...this.feeItem};
  }
}
</script>


<style lang="scss">
.v-btn.open-dialog {
  text-transform: none !important;
}
</style>