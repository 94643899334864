<template>
  <v-card tile outlined class="fill-height">
    <v-card-text>
      <template v-if="expensesLoaded">
        <v-data-table
          :headers="expenseTableHeaders"
          :items="expenseObjects"
          :loading="!expensesLoaded"
          :loading-text="$t('Loading...')"
          item-key="@id"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("FurtherExpenses") }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" small @click="createOrEditExpenseHandler(null)" :disabled="locked">
                {{ $t('AddExpense') }}
              </v-btn>
              <ExpenseDialog
                v-model="showExpenseDialogForm"
                :expense-item="expenseFormItem"
                :handle-created="onCreated"
                :handle-updated="onUpdated"
                :show-handle="showExpenseDialogForm"
                :title="$t('AddExpense')"
                @close="expenseDialogControl(true)"
              />
            </v-toolbar>
          </template>
          <template slot="item.amount" slot-scope="{ item }">
            {{ (item['amount']) }}€
          </template>
          <template slot="item.perParticipant" slot-scope="{ item }">
            <v-icon v-if="item.perParticipant">mdi-checkbox-outline</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          </template>
          <ActionCell
            v-if="!locked"
            :handle-delete="() => deleteExpenseHandler(props.item)"
            :handle-edit="() => createOrEditExpenseHandler(props.item)"
            slot="item.action"
            slot-scope="props"
          ></ActionCell>
        </v-data-table>
      </template>
      <template v-else>
        <p>Keine Daten verfügbar</p>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import ExpenseDialog from "@/components/expense/Dialog";
import ActionCell from "@/components/ActionCell";
import ExpenseMixin from "@/mixins/ExpenseMixin";

export default {
  name: 'ExpenseListAndAdd',
  components: {
    ExpenseDialog,
    ActionCell,
  },
  mixins: [
    ExpenseMixin
  ],
  props: {
    expenses: {
      type: Array,
      default: () => []
    },
    defaultItemProps: {
      type: Object,
      required: true,
    },
    showMessage: {
      type: Function,
      required: true
    },
    objectType: {
      type: String,
      required: true
    },
    locked: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      showExpenseDialogForm: false,
      expenseFormItem: {},
      defaultExpenseHeaders: [
        {text: this.$i18n.t('ExpenseText'), value: 'text'},
        {text: this.$i18n.t('ExpenseAmount'), value: 'amount'},
        {text: this.$i18n.t('ExpensePerParticipant'), value: 'perParticipant'}
      ],
    };
  },
  computed: {
    expenseObjects() {
      return this.resolveExpenseItems(this.expenses);
    },
    expensesLoaded() {
      return (this.expenses.length === this.expenseObjects.length);
    },
    expenseTableHeaders() {
      if (this.locked === false) {
        return this.defaultExpenseHeaders.concat([{
          text: this.$i18n.t('Actions'),
          value: 'action',
          sortable: false,
          align: 'end',
        }]);
      }
      return this.defaultExpenseHeaders;
    },
  },
  methods: {
    onCreated(item) {
      if (item[this.objectType] !== null) {
        this.expenses.push(item['@id']);
      }
      this.$emit('updateExpenses', this.expenses);
    },
    onUpdated() {
      this.$emit('updateExpenses', this.expenses);
    },
    createOrEditExpenseHandler(expenseItem) {
      this.expenseFormItem = expenseItem ?? this.defaultItemProps;
      this.expenseDialogControl();
    },
    expenseDialogControl(close = false) {
      this.showExpenseDialogForm = !close;
    },
    deleteExpenseHandler(item) {
      this.$store.dispatch('removeRelation', {
        iri: item['seminar'],
        relationName: 'expenses',
        toIri: item['@id']
      });
      this.deleteExpense(item);
    },
  },
  watch: {
    deletedExpenseItem() {
      this.showMessage(`Expense deleted.`);
      this.$emit('updateExpenses', this.expenses);
    },
  },
}
</script>
